import useApi from "@/hooks/useApi";
import useContactNotes from "@/hooks/useContactNotes";
import useContactTags from "@/hooks/useContactTags";
import { Contact } from "@/types";
import React, { useMemo, useState } from "react";
import Notes from "../Notes";
import Tags from "../Tags";
import FieldEditor from "./FieldEditor";
import Panel from "./Panel";
import AddressModal from "./AddressModal";
import Field from "./Field";
import useToastAlert from "@/hooks/useToastAlert";
import { Severity } from "@velaro/velaro-shared/src/components/ToastAlert";
import useTranslations from "@/hooks/useTranslations";

interface Props {
  contact: Contact;
  contactUpdated(contact: Contact): void;
}

export default function ContactPanel({ contact, contactUpdated }: Props) {
  const [showAddressModal, setShowAddressModal] = useState(false);
  const api = useApi();
  const contactNotes = useContactNotes(contact.id);
  const contactTags = useContactTags(contact.id);
  const toastAlert = useToastAlert();
  const translation = useTranslations();

  const contactEditFields: {
    field: keyof Contact;
    label: string;
    icon: string;
    onclick?: () => void;
  }[] = [
    { field: "name", label: translation.key("name"), icon: "fa fa-user" },
    {
      field: "email",
      label: translation.key("email"),
      icon: "fa-regular fa-envelope"
    },
    { field: "phone", label: translation.key("phone"), icon: "fa fa-phone" }
  ];

  const contactReadFields: {
    label: string;
    value: string;
  }[] = [
    { label: translation.key("key"), value: "test" },
    { label: translation.key("last_conversation"), value: "test" },
    { label: translation.key("contact_added"), value: "test" },
    { label: translation.key("assignee"), value: "test" }
  ];

  const location = useMemo(() => {
    if (
      !contact.address &&
      !contact.city &&
      !contact.state &&
      !contact.country
    ) {
      return "";
    }
    return `${contact.address || ""} ${contact.city || ""} ${
      contact.state || ""
    } ${contact.country || ""}`;
  }, [contact]);

  async function updateAttribute(key: keyof Contact, value: any) {
    const resp = await api.messaging.put(`ContactAttributes/${contact.id}`, {
      key,
      value
    });

    if (resp.ok) {
      contactUpdated({ ...contact, [key]: value });
    } else {
      toastAlert.displayToast(Severity.Error, "An unexpected error occurred");
    }
  }

  return (
    <Panel title={translation.key("contact_information")}>
      <div className="text-sm px-4 mb-4">
        <div className="mb-4">
          {contactEditFields.map((f, i) => (
            <div key={i}>
              <div className="flex items-center my-2">
                <div className="text-slate-gray-400 w-6">
                  <i className={f.icon}></i>
                </div>
                <FieldEditor
                  key={i}
                  value={contact[f.field as keyof Contact]?.toString() || ""}
                  onUpdate={(value) => updateAttribute(f.field, value)}
                  placeholder={f.label}
                />
              </div>
            </div>
          ))}
          <Field
            value={location}
            placeholder={translation.key("location")}
            icon={"fa fa-location-dot"}
            onClick={() => setShowAddressModal(true)}
          />
        </div>
        {showAddressModal && (
          <AddressModal
            contact={contact}
            onClose={() => setShowAddressModal(false)}
            updateAttribute={updateAttribute}
          />
        )}

        {/* <div className="mb-4">
          {contactReadFields.map((f, i) => (
            <div
              className="bg-gray-50 flex justify-between p-2 items-center text-xs"
              key={i}
            >
              <span className="font-semibold">{f.label}</span>
              <span>{f.value}</span>
            </div>
          ))}
        </div> */}
        <div className="mb-4">
          <Notes
            label={translation.key("notes")}
            modalTitle="Notes"
            modalSubTitle={contact.name}
            notes={contactNotes.data}
            onAddNote={contactNotes.add}
          />
        </div>

        <div>
          <Tags
            label={translation.key("tags")}
            allTags={contactTags.all}
            selectedTags={contactTags.selected}
            onSave={contactTags.save}
            onRemove={contactTags.remove}
          />
        </div>
      </div>
    </Panel>
  );
}
